import {getJsApiParams} from "@/api/routeApi";

export function getLocation(url) {
    return new Promise((resolve, reject) => {
        getJsApiParams(url).then(res => {
            const conf = res.data.data
            wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: conf.appId, // 必填，企业微信的corpID
                timestamp: conf.timestamp, // 必填，生成签名的时间戳
                nonceStr: conf.noncestr, // 必填，生成签名的随机串
                signature: conf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: ['checkJsApi', 'openLocation', 'getLocation'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function (res) {
                    var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    var point = new BMap.Point(longitude, latitude);
                    var convertor = new BMap.Convertor();
                    var pointArr = [];
                    pointArr.push(point);
                    convertor.translate(pointArr, 3, 5, function (data) {
                        if (data.status === 0) {
                            resolve(data.points[0])
                        }
                    })
                },
                fail: function (res) {
                    console.log(JSON.stringify(res) + "fail");
                },
                cancel: function (res) {
                    console.log('用户拒绝授权获取地理位置');
                }
            });
        }).catch(err => {
            reject(err)
        })
    })

}
