<template>
    <div>
        <el-button icon="el-icon-user-solid" @click="drawer = true" class="customer">
        </el-button>
        <van-row class="pr-ct">
          <van-col offset="6" span="19">
          <van-field
              readonly
              clickable
              :value="selPrCyValue"
              input-align="center"
              placeholder="请选择省市"
              @click="showPrCyPicker = true"
              right-icon="arrow-down"
              left-icon="location"
          />
          <van-popup v-model="showPrCyPicker" round position="bottom">
            <van-tree-select
                :items="prCyItems"
                :active-id.sync="prCyActiveId"
                :main-active-index.sync="prCyActiveIndex"
                @click-item="onClickItem"
                @click-nav="onClickNav"
            />
          </van-popup>
          </van-col>
        </van-row>
        <div ref="bmap" :style="{height:height+'px'}" id="map-container"></div>

        <el-drawer
                title="我是标题"
                :visible.sync="drawer"
                direction="ltr"
                size="70%"
                :with-header="false"
        >
            <div style="position: initial;">
                <el-input
                        style="position: sticky;top: 0;"
                        placeholder="客户名称"
                        v-model="customerSearch"
                        @change="customerSearchChange"
                        clearable>
                </el-input>
                <div class="customer-item" v-for="item in customerList" :key="item.crmCustomerid">
                    <el-button style="width: 100%;height: 100%;border: 0;" @click.native="customerClick(item)">
                        {{item.customerName}}
                    </el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import {getLocation} from "../../utils/wx";
    import {getBulkCrmCustomertwoList} from '@/api/customer'
    import {getPrCyItemsInfo} from '@/api/customer'


    // 信息框样式
    const opts = {
        width: 250,
        height: 180,
        title: "客户信息"
    };
    export default {
        name: 'CustomerArea',
        data() {
            return {
                height: window.innerHeight,
                map: {},
                drawer: false,
                customerSearch: '',
                customerList: [],
                customerBack: [],
                selPrCyValue: '',
                showPrCyPicker: false,
                prCyItems: [],
                prCyActiveId: '',
                prCyActiveIndex: -1,
                prCyActiveIndexId:'',
                province:'',
                city:''
            }
        },
        mounted() {
            this.initBMap()
            window.driver = this.driver
            window.toHistory = this.toHistory
            this.getCity()
            this.getProvince()
        },
        methods: {
            driver(event) {
                console.log(event)
                let element = document.elementFromPoint(event.clientX, event.clientY)

                var lat = element.getAttribute("lat");
                var lng = element.getAttribute("lng");
                var name = element.getAttribute("name");
                var address = element.getAttribute("address");

                wx.openLocation({
                    longitude: Number(lng),
                    latitude: Number(lat),
                    name: name,
                    address: address
                })
            },
            toHistory(id) {
              console.log(id)
              if (id == null || id == '' || id == 'undefined') {
                Notify({type: 'warning', message: '暂无历史内容！'});
              } else {
                this.$router.push('/ProjectProgressRecord1/' + id)
              }
            },
            onClickItem(data) {
              console.log(data, this.prCyActiveId, this.prCyActiveIndex);
              this.prCyActiveId = data.id

              let prCyActiveIndexText = ""
              let prCyActiveIdText = ""
              this.prCyItems.forEach((value, index) => {
                if (index == this.prCyActiveIndex) {
                  this.prCyActiveIndexId = value.text
                  prCyActiveIndexText = value.text

                  value.children.forEach((v) => {
                    if (v.id == this.prCyActiveId) {
                      prCyActiveIdText = v.text
                      return false
                    }
                  })
                  return false;
                }
              })

              if (prCyActiveIndexText.length > 0 && prCyActiveIdText.length > 0) {
                this.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
              }

              this.initCustomer1(prCyActiveIndexText)

              this.showPrCyPicker = false
          },
            onClickNav(index) {
              console.log(index);
              this.prCyActiveIndex = index
            },
            initBMap() {
                const that = this
                this.$nextTick(function () {
                    that.map = new BMap.Map(that.$refs.bmap)
                    that.map.addControl(new BMap.NavigationControl());

                    // 进行签名的时候  Android 不用使用之前的链接， ios 需要
                    let signLink = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.entryUrl;

                    getLocation(signLink).then(point => {
                        that.map.centerAndZoom(point, 15);
                        var myIcon = new BMap.Icon(require("@/assets/zuobiao.png"), new BMap.Size(32, 32), {anchor: new BMap.Size(14, 29)});
                        // 创建标注
                        var marker = new BMap.Marker(point, {icon: myIcon});
                        // 将标注添加到地图中
                        that.map.addOverlay(marker);
                    })
                    that.initCustomer()
                })
            },
            initCustomer() {
                const that = this
                const post = {
                    enterpriseType: '',
                    enterpriseNature: '',
                    customerGrade: '',
                    customerName: '',
                    customerRelationshipname: '',
                    isArrow: false,
                    current: 1,
                    size: 100,
                    addressProvince: ''
                }
                getBulkCrmCustomertwoList(post).then(res => {
                    res = res.data

                    // 打开信息窗口
                    function openInfo(content, e) {
                        var p = e.target;
                        var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
                        var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象
                        that.map.openInfoWindow(infoWindow, point); //开启信息窗口
                    }

                    // 添加点击事件
                    function addClickHandler(content, marker) {
                        marker.addEventListener("click", function (e) {
                            openInfo(content, e)
                        });
                    }


                    // 在地图中添加客户坐标的标注
                    for (var i = 0; i < res.length; i++) {
                        var mk = new BMap.Marker(new BMap.Point(res[i].addressLongitude, res[i].addressLatitude));
                        // var content = res[i].customerName + "\n" + res[i].customerStatus;
                        var content = "<p class='m0 font-12'><i class=\"el-icon-s-custom\"></i>客户：" + res[i].customerName + "</p>";
                        content += "<p class='m0 font-12'><i class=\"el-icon-location\"></i>地址：" + res[i].addressDetailed + "</p>";
                        content += "<button lat='" + res[i].addressLatitude + "' lng='" + res[i].addressLongitude + "' name='" + res[i].customerName + "' address='" + res[i].customerStatus + "' onclick='driver(event)'>导航</button>"
                        content += "<button style=' ' onclick= toHistory('" + res[i].customerName + "')>历史</button>" + "<p >"
                        that.map.addOverlay(mk);
                        addClickHandler(content, mk);
                        res[i].marker = mk
                    }
                    this.customerList = res
                    this.customerBack = res
                })
            },
            initCustomer1(province) {
              const that = this
              const post = {
                enterpriseType: '',
                enterpriseNature: '',
                customerGrade: '',
                customerName: '',
                customerRelationshipname: '',
                isArrow: false,
                current: 1,
                size: 100,
                addressProvince: province,
              }
              getBulkCrmCustomertwoList(post).then(res => {
                res = res.data
                if(res.length>0){
                  // 打开信息窗口
                  function openInfo(content, e) {
                    var p = e.target;
                    var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
                    var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象
                    that.map.openInfoWindow(infoWindow, point); //开启信息窗口
                  }

                  // 添加点击事件
                  function addClickHandler(content, marker) {
                    marker.addEventListener("click", function (e) {
                      openInfo(content, e)
                    });
                  }

                  this.customerClick(res[0])
                }
                else{
                  this.$message({
                    showClose: true,
                    message: '当前省份不存在客户！',
                    type: 'info'
                  });
                }
              })
            },
            customerSearchChange(val) {
                if (val === '') {
                    this.customerList = this.customerBack
                } else {
                    this.customerList = this.customerList.filter(item => item.customerName.indexOf(val) > -1)
                }
            },
            customerClick(item) {
                var lng = item.addressLongitude
                var lat = item.addressLatitude
                console.log(item.marker)
                var point = new BMap.Point(lng, lat);
                // var content = item.customerName + "\n" + item.customerStatus;
                // content += "<button lat='" + item.addressLatitude + "' lng='" + item.addressLongitude + "' name='" + item.customerName + "' address='" + item.customerStatus + "' onclick='driver(event)'>导航</button>"
                var content = "<p class='m0 font-12'><i class=\"el-icon-s-custom\"></i>客户：" + item.customerName + "</p>";
                  content += "<p class='m0 font-12'><i class=\"el-icon-location\"></i>地址：" + item.addressDetailed + "</p>";
                  content += "<button lat='" + item.addressLatitude + "' lng='" + item.addressLongitude + "' name='" + item.customerName + "' address='" + item.customerStatus + "' onclick='driver(event)'>导航</button>"
                  content += "<button><a onclick= toHistory('"+item.customerName+"')>历史</a></button>" + "<p >"
                var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象

                this.map.openInfoWindow(infoWindow, point); //开启信息窗口
                // 将客户点设置为中心
                this.map.centerAndZoom(point, 15);
                // 关闭侧边栏
                this.drawer = false
            },
            getCity() {
                const geolocation = new BMap.Geolocation();
                var _this = this;
                geolocation.getCurrentPosition(
                    function getinfo(position) {
                        let city = position.address.city; //获取城市信息
                        let province = position.address.province; //获取省份信息
                        _this.LocationPCity = province + " " + city;
                        _this.province = position.address.province;
                        _this.city = position.address.city;
                        console.log("LocationPCity:", _this.LocationPCity, position);
                        // alert(province + "," + city)
                    },
                    function (e) {
                        _this.LocationPCity = "定位失败";
                    },
                    {provider: "baidu"}
                );
            },
          getProvince(){
            const post = {
              cusId: 'ti01',
            }
            //各季度各航线招标货量 始发港
            getPrCyItemsInfo(post).then(res => {
              if (res.data != null) {
                this.prCyItems = res.data.prCyItems
                if (this.prCyItems && this.prCyItems.length > 0) {
                  //默认选择
                  this.prCyActiveIndex = 0
                  this.prCyActiveId = 0
                  let prCyActiveIndexText = ""
                  let prCyActiveIdText = ""
                  this.prCyItems.forEach((value, index) => {
                    if (value.text == prCyActiveIndexText) {
                      this.prCyActiveIndex = index
                      this.prCyActiveIndexId = value.text
                      value.children.forEach((v, i) => {
                        if (i == 0) {
                          this.prCyActiveId = v.id
                          prCyActiveIdText = v.text
                          return false
                        }
                      })
                      return false;
                    }
                  })
                  if (this.prCyActiveIndex > -1) {
                    // this.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
                  }
                }
              }
            })
          }
        }
    }
</script>
<style>
    .customer {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 999;
    }

    .pr-ct{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 999;
      opacity: 0.8;
    }

    .customer-item {
        border-top: 1px solid;
        font-weight: 600;
    }

    #map-container {
      height: 90vh;
    }


</style>
<style scoped>
    ::v-deep .marker-del {
      float: right;
      color: #f00;
    }

    ::v-deep .BMap_bubble_title {
      font-size: 14px;
      font-weight: bold;
      white-space: normal !important;
      line-height: 15px !important;
      margin-bottom: 3px;
    }

    ::v-deep .BMap_bubble_content {
      font-size: 12px;
    }

    .selectInfo .el-select {
      display: block;
    }

    ::v-deep .BMap_bubble_title {
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      padding-left: 10px;
      padding-top: 5px;
      border-bottom: 1px solid rgb(191, 191, 192);
      background-color: #79ccf3;
    }

    /* 消息内容 */
    ::v-deep .BMap_bubble_content {
      background-color: white;
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 10px;
    }

    /* 内容 */
    ::v-deep .BMap_pop div:nth-child(9) {
      top: 35px !important;
      border-radius: 7px;
    }

    /* 左上角删除按键 */
    ::v-deep .BMap_pop img {
      top: 45px !important;
      left: 720px !important;
    }

    ::v-deep .BMap_top {
      display: none;
    }

    ::v-deep .BMap_top div {
      background-color: white;
    }

    ::v-deep .BMap_bottom {
      display: none;
    }

    ::v-deep .BMap_center {
      display: none;
    }

    /* 隐藏边角 */
    ::v-deep .BMap_pop div:nth-child(1) div {
      display: none;
    }

    ::v-deep .BMap_pop div:nth-child(3) {
      display: none;
    }

    ::v-deep .BMap_pop div:nth-child(5) {
      display: none;
    }

    ::v-deep .BMap_pop div:nth-child(7) {
      display: none;
    }
</style>
